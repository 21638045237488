@use "../../scss" as *;

button {
  border-radius: inherit;
  border: 0;
  width: 8.5em;
  height: 2.7em;
}

.rgb-border .button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $gradient-rgb-button;
  text-align: center;
  text-decoration: none;
  color: $color-primary;
  font-size: 0.66em;
  letter-spacing: 1px;
  width: 100%;
  height: 100%;
  &:active {
    background: $gradient-radial-halloween;
    opacity: 0.9;
  }
}
