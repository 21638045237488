@use "colors.scss";

$gradient-rgb-button: linear-gradient(
  147deg,
  colors.$color-secondary 0%,
  #ff9e00 74%
);
$gradient-rgb-button-hover: linear-gradient(
  147deg,
  colors.$color-primary 0%,
  colors.$color-primary-1 74%
);
$gradient-rgb-input: linear-gradient(
  147deg,
  colors.$color-white 0%,
  #ecebeb 74%
);
$gradient-rgb: linear-gradient(
  155deg,
  colors.$color-border-0 0%,
  colors.$color-red 0%,
  colors.$color-border-1 12%,
  colors.$color-border-2 24%,
  colors.$color-border-3 36%,
  colors.$color-border-4 48%,
  colors.$color-border-5 72%,
  colors.$color-red 84%,
  colors.$color-border-6 96%
);

$gradient-radial-halloween: radial-gradient(
  #ff6d00ff,
  #ff7900ff,
  #ff8500ff,
  #ff9100ff,
  #ff9e00ff,
  #240046ff,
  #3c096cff,
  #5a189aff,
  #7b2cbfff,
  #9d4eddff
);
