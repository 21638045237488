@use "../../scss" as *;

input {
  border-radius: inherit;
  border: 0;
  width: 12.5em;
  height: 1.7em;
  margin: 0.5em;
  text-align: center;
  &:focus-visible {
    outline: $color-primary-1 auto;
  }
}

.rgb-border .input {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: $gradient-rgb-input;
  width: 100%;
  height: 100%;
  text-align: center;
  text-decoration: none;
  color: $color-primary;
  font-size: 0.66em;
  letter-spacing: 1px;
}
