$color-primary-1: #3c096cff;
$color-primary: #10002bff;
$color-secondary: #ff8500;
$color-secondary-1: #ff9e00;

$color-bg: #10002bff;
$color-red: red;
$color-white: white;
$color-black: black;
$color-border-0: #020024;
$color-border-1: #ffab00;
$color-border-2: #e0ff00;
$color-border-3: #00ff08;
$color-border-4: #00e4ff;
$color-border-5: #0005ff;
$color-border-6: #ffa200;

/* SCSS HEX */

$rich-black-fogra-29: #001219ff;
$blue-sapphire: #005f73ff;
$viridian-green: #0a9396ff;
$middle-blue-green: #94d2bdff;
$medium-champagne: #e9d8a6ff;
$gamboge: #ee9b00ff;
$alloy-orange: #ca6702ff;
$rust: #bb3e03ff;
$rufous: #ae2012ff;
$ruby-red: #9b2226ff;

/* SCSS HEX */
$prussian-blue: #012a4aff;
$indigo-dye: #013a63ff;
$indigo-dye-2: #01497cff;
$usafa-blue: #014f86ff;
$honolulu-blue: #2a6f97ff;
$celadon-blue: #2c7da0ff;
$blue-green: #468fafff;
$maximum-blue: #61a5c2ff;
$dark-sky-blue: #89c2d9ff;
$light-blue: #a9d6e5ff;

/* SCSS HEX */
$russian-violet: #10002bff;
$russian-violet-2: #240046ff;
$persian-indigo: #3c096cff;
$purple: #5a189aff;
$french-violet: #7b2cbfff;
$amethyst: #9d4eddff;
$heliotrope: #c77dffff;
$mauve: #e0aaffff;

/* SCSS HEX */
$safety-orange-blaze-orange: #ff6d00ff;
$safety-orange: #ff7900ff;
$orange: #ff8500ff;
$yellow-orange-color-wheel: #ff9100ff;
$orange-peel: #ff9e00ff;
