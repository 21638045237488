@use "scss" as *;

a {
  color: unset;
  text-decoration: none;
}

.App {
  color: $color-secondary;
  min-height: 100vh;
  justify-content: space-between;
}
.round {
  border-radius: 25px;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.rgb-border {
  background: $gradient-rgb;
  width: 6.7em;
  height: 1.7em;
  margin: 0.5em;
  position: relative;
  animation: rotation-flow 55s linear infinite;
  border-radius: inherit;
}

.rgb-border::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: inherit;
  filter: blur(4px);
  border-radius: inherit;
}

.vibrate {
  -webkit-animation: vibrate 0.3s linear infinite both;
  animation: vibrate 0.3s linear infinite both;
}

.hidden {
  opacity: 0;
  &-fade {
    @extend .hidden;
    transition: all 250ms linear 2s;
  }
}

.show {
  opacity: 1;
  transition: all 250ms linear;
}
